import HomePage from "./home_page";
import "./App.css";



function App() {
  return (
    <div className="App">
            <HomePage />
    </div>
  );
}

export default App;
